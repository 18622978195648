<template>
  <accordion :disabled="!sidenavActive" class="account-name">
    <template v-slot:title>
      <div class="name">
        <router-link :to="{ name: 'account' }"
          ><div class="user-avatar" :style="{ backgroundImage: 'url(' + avatarSrc + ')' }">
            <span v-if="!avatarSrc">{{ initials }}</span>
          </div></router-link
        >
        <div class="full-name remove-on-collapse" v-if="user">
          {{ user.personalInfos?.firstName }}
          <span>{{ user.personalInfos?.lastName }}</span>
        </div>
      </div>
    </template>
    <template v-slot:open>
      <i class="icon-carret remove-on-collapse"></i>
    </template>
    <template v-slot:content>
      <ul>
        <accountItem />
      </ul>
    </template>
  </accordion>
</template>

<script setup>
import AccountItem from './AccountItem';
import Accordion from '../commons/Accordion';
import { sidenavActive } from '@/composables/navigation';
import { computed } from 'vue';
import { apiURL } from '@/api/api';
import { findMediaByIdentifier } from '@/helper/mediaHelper';

const props = defineProps({
  user: {
    type: Object,
    required: true
  }
});

const avatar = computed(() => {
  if (null !== props.user) //PZ can be null
    return findMediaByIdentifier(props.user.medias, 'avatar');
  return null;
});

const cleanApiURL = computed(() => {
  if (null !== apiURL && apiURL.charAt(apiURL.length - 1) === '/') {
    return apiURL.substr(0, apiURL.length - 1);
  }
  return apiURL;
});

const avatarSrc = computed(() => {
  if (apiURL && avatar.value) {
    return (
      cleanApiURL.value +
      '/medias/users/' +
      avatar.value.resourceIdentifier +
      '/avatar/' +
      avatar.value.originalName
    );
  }
  return null;
});

const initials = computed(() => {
  if (null !== props.user) //PZ can be null
    return props.user.personalInfos.firstName.substring(0, 1).toUpperCase();
  return null;
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_accordion.scss';

.account-name {
  color: $white;
  text-align: left;
  background-color: $blue-1;
  @include transition(all 0.1s ease-out);
  transition-delay: 0s;
  overflow: visible;

  .user-avatar {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    //cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: $turquoise;
    color: white;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .name {
    //padding-top: 1rem;
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    span {
      display: block;
      text-transform: uppercase;
    }

    img {
      border-radius: 50%;
    }
  }
  :deep(.accordion-title) {
    padding: 0.5rem;
  }
}

.side-navigation.active .account-name {
  :deep(.accordion-title) {
    padding: 2rem 1rem 1rem;
  }
}

.remove-on-collapse,
.side-navigation .accordion.active .accordion-content {
  display: none;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition-delay: 0s;
}

.side-navigation.active .remove-on-collapse,
.side-navigation.active .accordion.active .accordion-content {
  display: inline-block;
  height: auto;
  width: auto;
  visibility: visible;
  opacity: 1;
  @include transition(all 0.2s ease-out);
  transition-delay: 0.5s;
}
</style>
